import { template as template_af51dd3905d246c5a0dda619f199f332 } from "@ember/template-compiler";
const FKLabel = template_af51dd3905d246c5a0dda619f199f332(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
