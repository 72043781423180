import { template as template_fed30d1793584fab8d48bb00f6f80ecb } from "@ember/template-compiler";
const FKControlMenuContainer = template_fed30d1793584fab8d48bb00f6f80ecb(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
