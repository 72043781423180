import { template as template_0ad6a8dd77ac46af9aa8c7700b2d6491 } from "@ember/template-compiler";
const FKText = template_0ad6a8dd77ac46af9aa8c7700b2d6491(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
