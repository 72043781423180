import { template as template_5f5b6f66d1fe45c58f6ee528b10e5751 } from "@ember/template-compiler";
const WelcomeHeader = template_5f5b6f66d1fe45c58f6ee528b10e5751(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
