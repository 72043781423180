import { template as template_35bc611b0ccc4c2b9b84f9f946853d4c } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_35bc611b0ccc4c2b9b84f9f946853d4c(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
