import { template as template_99c197aa7f6b4abab96c7748ef1a7887 } from "@ember/template-compiler";
const EmptyState = template_99c197aa7f6b4abab96c7748ef1a7887(`
  <div class="empty-state-container">
    <div class="empty-state">
      <span data-test-title class="empty-state-title">{{@title}}</span>
      <div class="empty-state-body">
        <p data-test-body>{{@body}}</p>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmptyState;
