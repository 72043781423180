import { template as template_b63c530c5b034e8c9bce5e96ea23216d } from "@ember/template-compiler";
const SidebarSectionMessage = template_b63c530c5b034e8c9bce5e96ea23216d(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
